
import { Options, Prop, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import TheoryPreExamsQuestion from "@/components/Pages/TheoryPreExams/TheoryPreExamsQuestion.vue";
import { namespace } from "s-vuex-class";
import moment from "moment/moment";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin.ts";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import isUndefined from "lodash/isUndefined";
import shuffle from "lodash/shuffle";
import TheoryPreExamsResult from "@/components/Pages/TheoryPreExams/TheoryPreExamsResult.vue";
import NavigationMixin from "@/mixins/NavigationMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const TheoryPreExamModule = namespace("theory-pre-exams");
const StudentEducationModule = namespace("student-education");

@Options({
  components: {
    TheoryPreExamsResult,
    TheoryPreExamsQuestion,
    DefaultLayout,
  },
})
export default class TheoryPreExamsQuestions extends mixins(ModalMixin, NavigationMixin) {
  public name = "TheoryPreExamsQuestions";
  // TODO redundant due to 'selectedPreExamStudentEducation'
  @Prop()
  public studentEducation!: any;
  private timeEnd = moment().add(45, "minutes");
  private timeLeft = "45:00";
  private timeLeftInterval: any;
  private showResults = false;

  private countMarkedQuestions = 0;
  private countBasicMarkedQuestions = 0;
  private countSpecificMarkedQuestions = 0;
  private countAnsweredQuestions = 0;

  // TODO remove selected without basic/specific
  private selectedQuestion: any = null;
  private selectedBasicQuestion: any = null;
  private selectedSpecificQuestion: any = null;

  private selectedQuestionTypeIndex = 0;

  @TheoryPreExamModule.Action("createPreExam")
  public createPreExamAction: any;

  @TheoryPreExamModule.Getter("getDataItem")
  public preExam: any;

  @TheoryPreExamModule.Action("setQuestions")
  public setQuestionsAction: any;

  @TheoryPreExamModule.Action("setBasicQuestions")
  public setBasicQuestionsAction: any;

  @TheoryPreExamModule.Action("setSpecificQuestions")
  public setSpecificQuestionsAction: any;

  @TheoryPreExamModule.Getter("getQuestions")
  public questions: any;

  @TheoryPreExamModule.Getter("getBasicQuestions")
  private basicQuestions: any;

  @TheoryPreExamModule.Getter("getSpecificQuestions")
  private specificQuestions: any;

  @TheoryPreExamModule.Action("addTheoryPreExamQuestionAnswers")
  public finishPreExamAction: any;

  @TheoryPreExamModule.Getter("getAnswerSuccess")
  public theoryPreExamsAnswerSuccess: any;

  @TheoryPreExamModule.Action("resetSave")
  public resetTheoryPreExams: any;

  @TheoryPreExamModule.Getter("getIsLoading")
  public loading: any;

  @StudentEducationModule.Getter("getSelectedPreExamStudentEducation")
  public selectedPreExamStudentEducation: any;

  @StudentEducationModule.Action("withTheory/findAll")
  public findAllStudentEducationWithTheoryExam!: (opt: ICrudOptions) => Promise<void>;

  @StudentEducationModule.Action("selectPreExamStudentEducationWithoutReset")
  public selectPreExamStudentEducation: any;

  @StudentEducationModule.Getter("withTheory/getDataList")
  public studentEducations: any;

  private get preExamProgress() {
    return this.selectedPreExamStudentEducation?.consecutiveSuccessfulPreExams || 0;
  }

  public referenceVehicleModal = false;
  public finishPracticeSecondWarningModal = false;
  private abortPreExamModalRef = "abortPreExamModal";
  private abgabeEnabled = true;

  private updateTimeLeft(): void {
    const now = moment(new Date());
    const duration = moment.duration(this.timeEnd.diff(now));
    const minutes = duration.minutes();
    const minutesDisplayed = minutes < 10 ? "0" + minutes : minutes;
    const seconds = duration.seconds();
    const secondsDisplayed = seconds < 10 ? "0" + seconds : seconds;
    this.timeLeft = minutes >= 0 && seconds >= 0 ? minutesDisplayed + ":" + secondsDisplayed : "00:00";
  }

  get countQuestionsLeft(): number {
    return this.questions && this.questions.length ? this.questions.length - this.countAnsweredQuestions : 0;
  }

  get unansweredSections(): Array<string> {
    const unanswered: Array<string> = [];
    if (this.basicQuestions.findIndex((q: any) => !q.answered) > -1) {
      unanswered.push(String(this.$t("general.basic_material")));
    }
    if (this.selectedPreExamStudentEducation && this.specificQuestions.findIndex((q: any) => !q.answered) > -1) {
      unanswered.push(this.selectedPreExamStudentEducation.mainLicenseClass);
    }
    return unanswered;
  }

  get sectionTitle(): string {
    return this.selectedQuestionTypeIndex === 0 ? String(this.$t("general.basic_material")) : this.selectedPreExamStudentEducation.mainLicenseClass;
  }

  /**
   * 0 - basic
   * 1 - specific
   * @param type
   * @private
   */
  private getQuestionsByType(type: number): Array<any> {
    return type === 0 ? this.basicQuestions : this.specificQuestions;
  }

  public mounted(): void {
    this.timeLeft = "44:59";
    this.timeLeftInterval = setInterval(() => {
      this.updateTimeLeft();
    }, 1000);
  }

  private onBeforeUnload(e: BeforeUnloadEvent): string {
    e.preventDefault();
    e.returnValue = this.$t("question.exam_ready");
    return String(this.$t("question.exam_ready"));
  }

  private isBasicQuestion(question: any): boolean {
    return (
      question.questionClass === "G" || question.questionClass === "GM" || question.questionClass === "G,GM" || question.questionClass === "GM,G"
    );
  }

  private selectQuestion(question: any): void {
    this.selectedQuestion = question;
    if (this.isBasicQuestion(question)) {
      this.selectedBasicQuestion = question;
    } else {
      this.selectedSpecificQuestion = question;
    }
  }

  private nextQuestion(): void {
    if (this.selectedQuestionTypeIndex === 0) {
      const selectedQuestionIndex = this.basicQuestions.findIndex((q: any) => q.id == this.selectedQuestion.id);
      if (selectedQuestionIndex < this.basicQuestions.length - 1) {
        this.selectedQuestion = this.basicQuestions[selectedQuestionIndex + 1];
        this.selectedBasicQuestion = this.basicQuestions[selectedQuestionIndex + 1];
      } else {
        this.selectedQuestionTypeIndex = 1;
        this.selectedQuestion = this.specificQuestions[0];
        this.selectedSpecificQuestion = this.specificQuestions[0];
      }
    } else {
      const selectedQuestionIndex = this.specificQuestions.findIndex((q: any) => q.id == this.selectedQuestion.id);
      if (selectedQuestionIndex < this.specificQuestions.length - 1) {
        this.selectedQuestion = this.specificQuestions[selectedQuestionIndex + 1];
        this.selectedSpecificQuestion = this.specificQuestions[selectedQuestionIndex + 1];
      } else {
        this.selectedQuestionTypeIndex = 0;
        this.selectedQuestion = this.basicQuestions[0];
        this.selectedBasicQuestion = this.basicQuestions[0];
      }
    }
  }

  private markSelectedQuestion(): void {
    this.selectedQuestion.marked = !this.selectedQuestion.marked;
    if (this.isBasicQuestion(this.selectedQuestion)) {
      this.selectedBasicQuestion.marked = this.selectedQuestion.marked;
    } else {
      this.selectedSpecificQuestion.marked = this.selectedQuestion.marked;
    }
    this.countBasicMarkedQuestions = this.basicQuestions.filter((q: any) => q.marked).length;
    this.countSpecificMarkedQuestions = this.specificQuestions.filter((q: any) => q.marked).length;
    this.countMarkedQuestions = this.countBasicMarkedQuestions + this.countSpecificMarkedQuestions;
  }

  private onAnswerGiven(answer: any) {
    // TODO spread and assign?
    this.selectedQuestion.answered = answer.given;
    this.selectedQuestion.answer = answer.answer;
    this.selectedQuestion.correct = answer.correct;
    if (this.isBasicQuestion(this.selectedQuestion)) {
      this.selectedBasicQuestion.answered = answer.given;
      this.selectedBasicQuestion.answer = answer.answer;
      this.selectedBasicQuestion.correct = answer.correct;
    } else {
      this.selectedSpecificQuestion.answered = answer.given;
      this.selectedSpecificQuestion.answer = answer.answer;
      this.selectedSpecificQuestion.correct = answer.correct;
    }
    const countAnsweredBasicQuestions = this.basicQuestions.filter((q: any) => q.answered).length;
    const countAnsweredSpecificQuestions = this.specificQuestions.filter((q: any) => q.answered).length;
    this.countAnsweredQuestions = countAnsweredBasicQuestions + countAnsweredSpecificQuestions;
  }

  private onVideoQuestionChange($event: any): void {
    if (!isNaN($event.countSeen)) {
      this.selectedQuestion.countSeen = $event.countSeen;
      if (this.isBasicQuestion(this.selectedQuestion)) {
        this.selectedBasicQuestion.countSeen = $event.countSeen;
      } else {
        this.selectedSpecificQuestion.countSeen = $event.countSeen;
      }
    }
    if (!isUndefined($event.questionsVisible)) {
      this.selectedQuestion.questionsVisible = $event.questionsVisible;
      if (this.isBasicQuestion(this.selectedQuestion)) {
        this.selectedBasicQuestion.questionsVisible = $event.questionsVisible;
      } else {
        this.selectedSpecificQuestion.questionsVisible = $event.questionsVisible;
      }
    }
  }

  private showReferenceVehicleModal(e: PointerEvent | null = null): void {
    this.showModal(e, "referenceVehicleModal");
  }

  private hideReferenceVehicleModal(e: PointerEvent | null = null): void {
    this.hideModal(e, "referenceVehicleModal");
  }

  private showFinishPracticeModal(e: PointerEvent | null = null): void {
    this.showModal(e, "finishPracticeWarningModal");
  }

  private hideFinishPracticeModal(e: PointerEvent | null = null): void {
    this.hideModal(e, "finishPracticeWarningModal");
  }

  private showFinishPracticeSecondModal(e: PointerEvent | null = null): void {
    this.showModal(e, "finishPracticeSecondWarningModal");
  }

  private hideFinishPracticeSecondModal(e: PointerEvent | null = null): void {
    this.hideModal(e, "finishPracticeSecondWarningModal");
  }

  private tryToFinish(): void {
    if (this.unansweredSections.length) {
      this.hideFinishPracticeModal();
      this.showFinishPracticeSecondModal();
    } else {
      this.finish();
    }
  }

  private finish(): void {
    // TODO code duplication
    let basicQuestionAnswers = this.basicQuestions.map((question: any) => {
      let answer = null;
      if (!isUndefined(question.answer)) {
        if (question.questionType === "MULTIPLE_CHOICE") {
          answer = question.answer
            .filter((a: any) => a.answer)
            .map((a: any) => a.position)
            .join(";");
        }
        if (question.questionType === "FREE_TEXT") {
          answer = question.answer.map((a: any) => a.answer).join(";");
        }
      }

      if (isArray(answer)) {
        answer = JSON.stringify(answer);
      }
      return {
        studentEducationId: this.$route.params.studentEducation,
        theoryQuestionId: question.id,
        correct: isUndefined(question.correct) ? false : question.correct,
        answer: answer,
      };
    });
    let specificQuestionAnswers = this.specificQuestions.map((question: any) => {
      let answer = null;
      if (!isUndefined(question.answer)) {
        if (question.questionType === "MULTIPLE_CHOICE") {
          answer = question.answer
            .filter((a: any) => a.answer)
            .map((a: any) => a.position)
            .join(";");
        }
        if (question.questionType === "FREE_TEXT") {
          answer = question.answer.map((a: any) => a.answer).join(";");
        }
      }

      if (isArray(answer)) {
        answer = JSON.stringify(answer);
      }
      if (null === answer) {
        answer = "";
      }
      return {
        studentEducationId: this.$route.params.studentEducation,
        theoryQuestionId: question.id,
        correct: isUndefined(question.correct) ? false : question.correct,
        answer: answer,
      };
    });
    const questionAnswers = basicQuestionAnswers.concat(specificQuestionAnswers);
    this.hideFinishPracticeModal();
    this.hideFinishPracticeSecondModal();
    this.finishPreExamAction({
      theoryPreExamId: this.preExam.id,
      questionAnswers: questionAnswers,
    });
  }

  @Watch("studentEducation", { immediate: true, deep: true })
  private onStudentEducationChange(studentEducation: any): void {
    if (studentEducation && (!this.preExam || !this.preExam.id || this.preExam.successfullyPassed)) {
      this.createPreExamAction(studentEducation);
    }
  }

  @Watch("preExam", { immediate: true, deep: true })
  private onPreExamChange(preExam: any): void {
    if (preExam && preExam.theoryQuestions && preExam.theoryQuestions.length) {
      const questions = preExam.theoryQuestions.map((q: any) => {
        const question = cloneDeep(q);
        question.marked = false;
        question.answered = false;
        if (question.questionType === "MULTIPLE_CHOICE") {
          const options = shuffle(q.options);
          question.options = options;
        }

        if (question.withVideo) {
          question.countSeen = 5;
          question.questionsVisible = false;
        }
        return question;
      });
      this.setQuestionsAction(questions);
      const basicQuestions = this.questions.filter((q: any) => this.isBasicQuestion(q));
      this.setBasicQuestionsAction(basicQuestions);
      const specificQuestions = this.questions.filter((q: any) => !this.isBasicQuestion(q));
      this.setSpecificQuestionsAction(specificQuestions);

      // TODO watcher?
      this.selectedQuestion = this.basicQuestions.length > 0 ? this.basicQuestions[0] : this.questions[0];
      this.selectedBasicQuestion = this.basicQuestions[0];
    }
  }

  @Watch("theoryPreExamsAnswerSuccess", { immediate: true, deep: true })
  private async onTheoryPreExamsAnswerSuccessChange(success: any): Promise<void> {
    if (isBoolean(success)) {
      await this.findAllStudentEducationWithTheoryExam({
        resource: "/student-educations/student/current",
        descriptionField: "",
      });

      const foundEducation = this.studentEducations?.find((edu: any) => edu.id == this.studentEducation);

      if (foundEducation) {
        this.selectPreExamStudentEducation(foundEducation);
      }

      this.showResults = true;
    }
  }

  // @Watch("preExamProgressError")
  // private onPreExamProgressError(error: any) {
  //   if (error) {
  //     this.showResults = false;
  //     this.abgabeEnabled = false;
  //     this.$toasted.error("Beim Laden der Übersicht ist ein Fehler aufgetreten. Ihre Prüfung wurde gespeichert.");
  //   }
  // }

  @Watch("selectedQuestionTypeIndex", { immediate: true, deep: true })
  private onSelectedQuestionTypeIndexChange(index: any): void {
    if (!this.selectedQuestion) {
      return;
    }
    if (index === 0) {
      if (!this.selectedBasicQuestion) {
        this.selectedBasicQuestion = this.basicQuestions[0];
      }
      this.selectedQuestion = this.selectedBasicQuestion;
    } else {
      if (!this.selectedSpecificQuestion) {
        this.selectedSpecificQuestion = this.specificQuestions[0];
      }
      this.selectedQuestion = this.selectedSpecificQuestion;
    }
  }

  private reset(): void {
    this.resetTheoryPreExams();
  }

  private onAbortPreExam(e: PointerEvent | null = null): void {
    this.showModal(e, this.abortPreExamModalRef);
  }

  private okAbort(e: PointerEvent | null = null) {
    this.hideModal(e, this.abortPreExamModalRef);
    this.$router.push({ name: "Home" });
  }

  private cancelAbort(e: PointerEvent | null = null) {
    this.hideModal(e, this.abortPreExamModalRef);
  }
}
