
import { Options, Prop, Watch } from "vue-property-decorator";
import VideoQuestion from "@/components/Pages/TheoryPreExams/Questions/VideoQuestion.vue";
import ImageQuestion from "@/components/Pages/TheoryPreExams/Questions/ImageQuestion.vue";
import BaseQuestion from "@/components/Pages/TheoryPreExams/Questions/BaseQuestion.vue";
import { mixins } from "vue-class-component";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";

@Options({
  components: { BaseQuestion, ImageQuestion, VideoQuestion },
})
export default class TheoryPreExamsQuestion extends mixins(QuestionMixin) {
  @Prop()
  public question!: any;

  @Prop()
  public studentEducation!: any;

  private favourite = false;

  @Watch("question", { immediate: true, deep: true })
  private onQuestionChange(question: any): void {
    if (question) {
      this.favourite = question.favourite;
    }
  }
}
