import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-3 align-items-center mr-3"
}
const _hoisted_2 = {
  key: 1,
  class: "practice-question-checkbox big-checkbox-bl mt-3"
}
const _hoisted_3 = {
  class: "checkbox-text",
  style: {"color":"black"}
}
const _hoisted_4 = {
  key: 2,
  class: "practice-question-checkbox big-checkbox-bl mt-3 align-items-center"
}
const _hoisted_5 = {
  class: "checkbox-text",
  style: {"color":"black"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.position,
        class: "d-flex flex-row"
      }, [
        (_ctx.showCorrectAnswers)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("i", {
                class: "fas fa-play",
                style: _normalizeStyle(_ctx.isAnswerIndexCorrectStyle(_ctx.answers[index].value))
              }, null, 4)
            ]))
          : _createCommentVNode("", true),
        (_ctx.showCorrectAnswers)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_b_form_checkbox, {
                modelValue: option.value,
                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                disabled: _ctx.showCorrectAnswers
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(option.text), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_b_form_checkbox, {
                modelValue: option.value,
                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                disabled: _ctx.showCorrectAnswers,
                onChange: ($event: any) => (_ctx.onAnswerChange($event, option.position))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(option.text), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
            ]))
      ]))
    }), 128))
  ]))
}