
import { Options, Prop } from "vue-property-decorator";
import MultiChoice from "@/components/Pages/TheoryPreExams/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryPreExams/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import { mixins } from "vue-class-component";

@Options({
  components: { FreeText, MultiChoice },
})
export default class BaseQuestion extends mixins(QuestionMixin) {
  public name = "BaseQuestion";

  @Prop({ default: false })
  public disabled!: any;
}
