import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoQuestion = _resolveComponent("VideoQuestion")!
  const _component_image_question = _resolveComponent("image-question")!
  const _component_base_question = _resolveComponent("base-question")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createBlock(_component_b_container, { fluid: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { class: "theory-question" }, {
            default: _withCtx(() => [
              (_ctx.question.withVideo)
                ? (_openBlock(), _createBlock(_component_VideoQuestion, {
                    key: 0,
                    question: _ctx.question,
                    disabled: false,
                    onOnAnswerGiven: _cache[0] || (_cache[0] = ($event: any) => (_ctx.answerGiven($event))),
                    onOnVideoQuestionChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onVideoQuestionChange', $event)))
                  }, null, 8, ["question"]))
                : (_ctx.question.withPicture)
                  ? (_openBlock(), _createBlock(_component_image_question, {
                      key: 1,
                      question: _ctx.question,
                      disabled: false,
                      onOnAnswerGiven: _cache[2] || (_cache[2] = ($event: any) => (_ctx.answerGiven($event)))
                    }, null, 8, ["question"]))
                  : (_openBlock(), _createBlock(_component_base_question, {
                      key: 2,
                      question: _ctx.question,
                      disabled: false,
                      onOnAnswerGiven: _cache[3] || (_cache[3] = ($event: any) => (_ctx.answerGiven($event)))
                    }, null, 8, ["question"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}