import { Options, Vue, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const StudentEducationModule = namespace("student-education");
@Options({})
export default class StudentPreExamEducationMixin extends Vue {
  @StudentEducationModule.Action("selectPreExamStudentEducation")
  public selectPreExamStudentEducation: any;

  @StudentEducationModule.Getter("getSelectedPreExamStudentEducation")
  public selectedEducation: any;

  protected selectedEducationIndex = 0;

  @Watch("studentEducationWithTheory", { immediate: true, deep: true })
  protected onStudentEducationsChange(studentEducations: Array<any>): void {
    let filteredEducations = [];
    if (studentEducations) {
      filteredEducations = studentEducations.filter((education: any) => education.theoryRequired);
    }
    if (filteredEducations && filteredEducations.length) {
      if (!this.selectedEducation || !this.selectedEducation.id) {
        this.selectEducation(filteredEducations[0]);
        this.selectedEducationIndex = 0;
      } else {
        const index = filteredEducations.findIndex((se: any) => se.id == this.selectedEducation.id);

        this.selectEducation(filteredEducations[index]);
        this.selectedEducationIndex = index > -1 ? index : 0;
      }
    }
  }

  protected selectEducation(education: any): void {
    this.selectPreExamStudentEducation(education);
  }
}
