
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import isNumeric from "@/utils/isNumeric";

@Options({
  components: {},
})
export default class FreeText extends Vue {
  @Prop()
  public question!: any;
  @Prop({ default: false })
  public showCorrectAnswers!: any;
  @Prop({ default: false })
  public disabled!: any;
  private answers: Array<any> = [];
  private textSlices: Array<string> = [];
  private inputFields: Array<any> = [];
  private answered = false;

  private onAnswerChange(value: string, position: any): void {
    const val = value.trim();
    const answer = this.answers.find((a: any) => a.position == position);

    if (isNumeric(answer.value) && isNumeric(val)) {
      answer.correct = parseFloat(answer.value.replace(",", ".")) === parseFloat(val.replace(",", "."));
    } else {
      answer.correct = answer.value == val;
    }

    answer.answer = val;
    this.answered = this.answers.findIndex((a: any) => a.answer.length) > -1;
    this.answerGiven();
  }

  // TODO code duplication
  public isAnswerCorrect(): boolean {
    return this.answers.findIndex((a: any) => !a.correct) === -1;
  }

  // TODO code duplication
  private answerGiven(): void {
    this.$emit("onAnswerGiven", {
      given: this.answered,
      answer: this.answers,
      correct: this.isAnswerCorrect(),
    });
  }

  @Watch("question", { immediate: true, deep: true })
  public onQuestionChange(question: any): void {
    if (question) {
      const regex = /\{[0-9]+\}/g;
      this.textSlices = question.questionPattern.split(regex);
      if (question.answer) {
        this.inputFields = question.answer.map((a: any, index: number) => {
          return { index: index, value: a.answer };
        });
        this.answers = question.answer;
      } else {
        this.inputFields = question.questionPattern.match(regex);
        this.inputFields =
          this.inputFields?.map((iField: any, index: number) => {
            return { index: index, value: "" };
          }) || [];
        const answerPositions = question.correctAnswer.split(";");
        this.answers = answerPositions.map((a: any, index: number) => {
          return {
            position: index,
            value: a,
            correct: false,
            answer: "",
          };
        });
      }
    }
  }
}
